.header.sticky {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 91;
}

.header.sticky .navBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 6px 13px 0px rgb(0 0 0 / 6%);
  opacity: 0;
  -webkit-animation: fadeInDown .95s ease-in-out 0s 1 normal none running;
  animation: fadeInDown .95s ease-in-out 0s 1 normal none running;
  -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  opacity: 1;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

.navBg {
  background-color: #FFDC23;  
}

.aligncenter{
  align-items: center;
}

.navlogo {  
  padding-top: 0em;
}

.logo {
  max-height: 80px;
}

.navicon {  
  font-size: 3rem;
}

.navicon a{
  padding-top: 0em;
}

.logotxt {
  font-size: 26px;
  font-family: Heebo;
  margin-top: -0.5em;
  margin-bottom: 0em;
}

.logotxt b{
  font-family: LuckiestGuy;
  font-style: normal;
}

.iconbet {
  margin-right: 25px;
}

.connetbtn {  
  font-size: 26px;
  padding: 0.2em 2em;
  border-radius: 18px;
  background-color: black;
  color: white;  
  font-family: LuckiestGuy;
}

.connetbtn:hover{
  opacity: 0.8;
}

.siteBg{
  background-color: #FFDC23;
}


@media only screen and (max-width: 1400px) {
	.uncleimg{width: 400px;}
  .navicon {font-size: 3rem;}  
  .connetbtn {font-size: 22px; padding: 0.2em 1.2em;border-radius: 16px;}
  .logotxt {font-size: 26px;}
  .iconbet {margin-right: 25px;}
  .logo {
    max-height: 60px;
  }
}
@media only screen and (max-width: 1200px) {
  .uncleimg{width: 300px;}
  .navicon {font-size: 2.5rem;}  
  .connetbtn {font-size: 18px; padding: 0em 1em;border-radius: 14px;}
  .logotxt {font-size: 22px;}
  .iconbet {margin-right: 20px;}
}
@media only screen and (max-width: 992px) {
  .uncleimg{width: 200px;}
  .navicon {font-size: 2rem;}  
  .connetbtn {font-size: 16px; padding: 0em 0.5em;border-radius: 12px;}
  .logotxt {font-size: 18px;}
  .iconbet {margin-right: 15px;}
}
@media only screen and (max-width: 768px) {
  .logo {
    max-height: 40px;
  }
  .uncleimg{width: 200px;}
  .navicon {font-size: 1.5rem;}  
  .logotxt {font-size: 14px;}
  .iconbet {margin-right: 10px;}
}
@media only screen and (max-width: 576px) {
  .uncleimg{width: 150px;}
  .navicon {font-size: 1rem;}  
  .connetbtn {font-size: 12px; padding: 0em 0.3em;border-radius: 8px;}
  .logotxt {font-size: 14px;}
  .iconbet {margin-right: 5px;}
}