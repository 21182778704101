.nftBg {
  background-color: #FFDC23;
  background-size: 100% 100%;
}

.eyetext {
    font-size: 76px;
    text-align: center;
    margin-top: 7.6rem;
    margin-left: 6rem;
    margin-right: 6rem;
    margin-bottom: 4rem;
}

.eyetext-s{
    font-size: 42px;
    margin-bottom: 1rem;
}

.eyetext-n{
    font-size: 42px;
    margin-bottom: 2rem;
}

.eyetext-d{
    font-size: 24px;
}

.eyepan {
    background-size: 100% 100%;    
    font-family: LuckiestGuy;
}

.eyecal{
    margin-left: 20%;
    margin-right: 20%;
}

.eyedown {
    align-self: flex-end;
}

.eyeimsize  {
    width: 25%;
}

.eyeimfit{
    width: 100%;
    background: transparent;
}

.mintbtn{
    display: block;
    text-decoration: none;
    margin-right: auto;
    margin-left: auto;
    background-color:#D3D3D3;
    width: 55%;
    border-radius: 20px;
    padding: 2%;
    font-size: 250%;
    color: white;
    border: 0px;
    margin-bottom: 15rem;
}

.mint-status {
  font-size: 1.5em;
  margin-top: 1em;
  margin-bottom: 2em;
}

.mintbtn.disabled {
    user-select: none;
    pointer-events: none;
    cursor: pointer;
}

.mintbtn:hover, .mintbtn-active {
    background-color: #861212;    
}

.eyepan a:hover {
    color: rgba(255, 255, 255, 0.75);
}

@media only screen and (max-width: 1400px) {
    .eyetext {font-size: 76px;margin-top: 7rem;margin-left: 5rem;margin-right: 5rem;margin-bottom: 3.5rem;}
    .eyetext-s, .eyetext-d{font-size: 34px;}
    .eyetext-n{font-size: 24px;}
    .eyeimsize  {width: 25%;}
    .eyecal{margin-left: 14%;margin-right: 14%;}
    .mintbtn{width: 55%;border-radius: 20px;padding: 2%;font-size: 250%;margin-bottom: 15rem;}
}

@media only screen and (max-width: 1200px) {
    .eyetext {font-size: 66px;margin-top: 6rem;margin-left: 4rem;margin-right: 4rem;margin-bottom: 3rem;}
    .eyetext-s, .eyetext-d{font-size: 27px;}
    .eyetext-n{font-size: 20px;}
    .eyeimsize  {width: 20%;}
    .eyecal{margin-left: 13%;margin-right: 13%;}
    .mintbtn{width: 55%;border-radius: 20px;padding: 2%;font-size: 250%;margin-bottom: 11rem;}
    .mint-status {
        font-size: 3em;
    }
}

@media only screen and (max-width: 992px) {
    .eyetext {font-size: 56px;margin-top: 5rem;margin-left: 3rem;margin-right: 3rem;margin-bottom: 2rem;}
    .eyetext-s, .eyetext-d{font-size: 20px;}
    .eyetext-n{font-size: 18px;}
    .eyeimsize  {width: 15%;}
    .eyecal{margin-left: 12%;margin-right: 12%;}
    .mintbtn{width: 55%;border-radius: 20px;padding: 2%;font-size: 250%;margin-bottom: 8rem;}
    .mint-status {
        font-size: 2.5em;
    }
}

@media only screen and (max-width: 768px) {
    .eyetext {font-size: 46px;margin-top: 4rem;margin-left: 2rem;margin-right: 2rem;margin-bottom: 1rem;}
    .eyetext-s, .eyetext-d{font-size: 16px;}
    .eyeimsize  {width: 25%;}
    .eyetext-n{font-size: 10px;}
    .eyecal{margin-left: 11%;margin-right: 11%;}
    .mintbtn{width: 55%;border-radius: 20px;padding: 2%;font-size: 200%;margin-bottom: 5rem;}
    .mint-status {
        font-size: 2em;
    }
}

@media only screen and (max-width: 576px) {
    .eyetext {font-size: 36px;margin-top: 4rem;margin-left: 2rem;margin-right: 2rem;margin-bottom: 1rem;}
    .eyetext-s, .eyetext-d{font-size: 14px;}
    .eyetext-n{font-size: 12px;}
    .eyeimsize  {width: 0%;}
    .eyecal{margin-left: 10%;margin-right: 10%;}
    .mintbtn{width: 55%;border-radius: 20px;padding: 2%;font-size: 150%;margin-bottom: 2rem;}
}

@media only screen and (min-width: 1200px) {
    .mint-status {
        font-size: 3em;
    }
}

