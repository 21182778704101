.team-section {
  background-color: #FFDC23;
  padding: 203px 0px 166px
}
.team-section-title {
  font-family: LuckiestGuy;
  font-size: 110px;
  line-height: 110px;
  color: #000;
}
.team-contents {
  margin-left: -17.5px;
  margin-right: -17.5px;
  margin-top: 93px;
  flex-wrap: wrap;
}
.team-item {
  width: 25%;
  padding-left: 17.5px;
  padding-right: 17.5px;
}
.team-item img {
  width: 100%;
}
.team-item h3 {
  font-size: 76px;
  line-height: 50px;
  color: #000;
  font-weight: 400;
  font-family: LuckiestGuy;
  margin-top: 97px;
}
.team-item h4 {
  font-size: 35px;
  line-height: 50px;
  font-weight: 400;
  color: #000;  
  font-family: LuckiestGuy;
} 
.team-item p {
  margin-top: 18px;
  font-size: 24px;
  line-height: 35px;
  font-family: Heebo;
}
@media screen and (min-width: 1700px) {
  .team-section .container {
    max-width: 1660px;
  }
}
@media screen and (max-width: 1200px) {
  .team-section-title {
    font-size: 90px;
  }
  .team-item {
    width: 50%;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 991px) {
  .team-item h3 {   
    margin-top: 40px;
  }
  .team-item h4 {   
    line-height: 1;   
  } 
  .team-item p {
    margin-top: 10px;   
  }
}
@media screen and (max-width: 767.98px) {
  .team-section .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .team-section-title {
    font-size: 50px;
  }
  .team-contents {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 93px;
  }
  .team-item h3 {
    font-size: 25px;
    line-height: 1;  
    margin-top: 20px;
  }
  .team-item h4 {
    font-size: 20px;
    line-height: 1;   
  } 
  .team-item p {
    margin-top: 10px;
    font-size: 16px;
    line-height: 24px;    
  }
}
@media screen and (max-width: 576px) {
  .team-section {   
    padding: 80px 0px 40px
  }
  .team-section-title {
    font-size: 40px;
    line-height: 1;
  }
  .team-contents {
    margin-top: 35px;
  }
  .team-item {
    padding-left: 15px;
    padding-right: 15px;
  }
  .team-item h3 {
    font-size: 20px;   
    margin-top: 20px;
  }
  .team-item h4 {
    font-size: 18px;   
  } 
  .team-item p {
    margin-top: 10px;
    font-size: 14px;
    line-height: 18px;    
  }
}
